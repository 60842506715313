<template>
  <div>
    <section class="white">
      <v-container>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <h2
              :class="[$vuetify.breakpoint.mdAndUp ? 'display-3' : 'headline']"
              class="mb-4"
            >
              Δίπλωμα Μηχανής Α1
            </h2>

            <p class="subtitle-1">
              Μοτοσικλέτα  max:125 cm3 ή 11kw και max  0.1kw/kg. Καθώς και τρίκυκλα max 15KW . Το ελάχιστο όριο ηλικίας για την απόκτησή του διπλώματος είναι το 18ο έτος.
            </p>
            <br>
            <p class="display-1">
              ΠΡΟΫΠΟΘΕΣΕΙΣ
            </p><p class="subtitle-1">
              <ul>
                <li>Να έχει την κανονική του διαμονή στην Ελλάδα.</li>
                <li>Ηλικία άνω του 18ου έτους της ηλικίας του για άδεια οδήγησης μοτοσικλέτας ισχύος max 11kW ή με λόγο ισχύος προς βάρος max 0,1kW/kg</li>
                <li>Να μην κατέχει άλλη άδεια οδήγησης, ίδιας κατηγορίας, ελληνική ή κράτους-μέλους της Ε.Ε.(συμπεριλαμβανομένων των κρατών Νορβηγίας, Ισλανδίας και Λιχτενστάιν).</li>
                <li>Να έχει τις ελάχιστες προϋποθέσεις σωματικής & διανοητικής ικανότητας που ισχύουν για την κατηγορία Α***.</li>
                <li>Να επιτύχει σε θεωρητική και πρακτική εξέταση, μετά από υποχρεωτική θεωρητική και πρακτική εκπαίδευση.</li><br>
              </ul>
            </p>
            <v-btn
              color="success"
              class="px-5"
              large
              :to="`/contact-me`"
            >
              Επικοινωνηστε τωρα
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            md="5"
            offset-md="1"
          >
            <v-card>
              <v-img src="/static/a1.png" />
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section class="grey lighten-3 pb-10 pt-10">
      <v-container>
        <v-row>
          <v-col
            v-for="([icon, number, name, desc, desc2], i) in experiences"
            :key="i"
            cols="12"
            md="6"
          >
            <div class="text-center">
              <div
                class="text-uppercase headline font-weight-bold mb-2"
                v-text="name"
              />
              <v-icon
                size="84"
                class="mb-3"
                v-text="icon"
              />
              <div
                class="display-2 font-weight-bold mb-2"
                v-text="number"
              />
              <div
                class="font-weight-bold"
                v-text="desc"
              />
              <div
                class="font-weight-bold"
                v-text="desc2"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section class="white pb-0">
      <v-container>
        <h2
          :class="[$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline']"
          class="mb-4"
        >
          Απαιτούμενα δικαιολογητικά
        </h2>
        <v-row>
          <v-col
            v-for="(highlight, i) in highlights"
            :key="i"
            cols="12"
            md="6"
          >
            <v-card-title v-text="highlight" />
            <v-text
              v-if="highlight=='Αν ο υποψήφιος ΔΕΝ έχει άλλο δίπλωμα'"
            >
              <ul>
                <li>Ταυτότητα ή ελληνικό διαβατήριο σε ισχύ. (Για όσους δεν έχουν ελληνική υπηκοότητα χρειάζεται άδεια διαμονής που να εμφανίζονται 186 ημέρες συνεχής διαμονής στην Ελλάδα και διαβατήριο</li>
                <li>ΑΦΜ (αν δεν υπάρχει πρέπει να εκδοθεί από την εφορία.</li>
                <li>Τέσσερις (4) έγχρωμες φωτογραφίες διαβατηρίου</li>
                <li>Παράβολο 60€(κωδ: 20) 30€(κωδ: 28) και 18€(κωδ: 61)</li>
                <li>Έκδοση των πιστοποιητικών υγείας από συμβεβλημένους με το Υ.Μ.Ε. (Παθολόγο και Οφθαλμίατρο) μαζί με την απόδειξη των γιατρών</li>
                <li>Παράβολα 60€ (κωδ: 20), 30€ (κωδ: 28) και 18€ (κωδ: 61) για την έκδοση νέου διπλώματος, 15€ (κωδ: 2037) για τις θεωρητικές εξετάσεις και 15€ (κωδ: 2038) για τις πρακτικές εξετάσεις.</li>
                <li>Βιβλιάριο Ασθενείας (αν δεν υπάρχει χρειάζονται οι εξής εξετάσεις: Γενική αίματος, ουρία, ακτινογραφία θώρακος, καρδιογράφημα)</li>
                <li>Υπεύθυνη δήλωση και αίτηση εκτύπωσης (τις προμηθεύεστε από τη σχολή ή τις κατεβάζετε από το site)</li>
                <li>Έγγραφο με την ομάδα αίματος για να αναγράφεται στο δίπλωμα (δεν είναι υποχρεωτικό)</li>
              </ul>
            </v-text>
            <v-text v-if="highlight=='Αν ο υποψήφιος ΕΧΕΙ άλλο δίπλωμα'">
              <ul>
                <li>Ταυτότητα ή ελληνικό διαβατήριο σε ισχύ. (Για όσους δεν έχουν ελληνική υπηκοότητα χρειάζεται άδεια διαμονής που να εμφανίζονται 186 ημέρες συνεχής διαμονής στην Ελλάδα και διαβατήριο</li>
                <li>ΑΦΜ (αν δεν υπάρχει πρέπει να εκδοθεί από την εφορία.</li>
                <li>Δύο (2) έγχρωμες φωτογραφίες διαβατηρίου</li>
                <li>Παράβολο 30€ (κωδ: 28) για την έκδοση του διπλώματος παράβολο παραλαβής  27,02€ (κωδ: 62),για θεωρητικές εξετάσεις 15€ (κωδ: 2037) και για τις πρακτικές εξετάσεις 15€ (κωδ: 2038)</li>
                <li>Φωτοτυπία διπλώματος</li>
                <li>Υπεύθυνη δήλωση και αίτηση εκτύπωσης (τις προμηθεύεστε από τη σχολή ή τις κατεβάζετε από το site)</li>
                <li>Έγγραφο με την ομάδα αίματος για να αναγράφεται στο δίπλωμα (προαιρετικά)</li>
              </ul>
            </v-text>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section class="white pt-0">
      <v-container>
        <div class="text-center font-weight-bold">
          <v-text>
            Τα παράβολα εκδίδονται απο την εφορία ή ηλεκτρονικά απο το <a href="https://www1.gsis.gr/sgsisapps/eparavolo/public/welcome.htm?fbclid=IwAR3fE4tXJ-I1NlFsMfud2NLlWjn13VrFOmBm7AICQlCda0kdDPQt2XN73ww">e-paravolo</a> και τα αναλαμβάνει και η σχολή να τα εκδώσει χωρίς επιβάρυνση!
          </v-text>
        </div>
      </v-container>
    </section>

    <section class="grey lighten-3">
      <v-container>
        <v-row>
          <v-col
            v-for="([name, path], i) in pdfs"
            :key="i"
            cols="6"
            md="6"
          >
            <div class="text-center">
              <div
                class="headline font-weight-bold mb-3"
                v-text="name"
              />
              <v-btn
                color="success"
                class="px-5"
                large
                @click="readFile(path)"
              >
                ΛΗΨΗ
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
  export default {
    data: () => ({
      highlights: [
        'Αν ο υποψήφιος ΔΕΝ έχει άλλο δίπλωμα',
        'Αν ο υποψήφιος ΕΧΕΙ άλλο δίπλωμα'
      ],
      experiences: [
        ['mdi-steering', '14 ώρες', 'Πρακτικα μαθηματα', '(12 ώρες με δίπλωμα κατ. Β)', '(7 ώρες με δίπλωμα άλλης κατ. μηχανής)'],
        ['mdi-book-open-variant', '32 ώρες', 'θεωρητικα μαθηματα', '(11 ώρες με άλλο δίπλωμα)']
      ],
      pdfs: [
        ['Εκτύπωση Αίτησης', 'static/εκτύπωση-αίτησης.pdf'],
        ['Αρχική Χορήγηση Άδειας Οδήγησης', 'static/αρχική-ΑΒ.pdf'],
        ['Επέκταση Άδειας Οδήγησης', 'static/m-tao02-epektasi-a-b-be.pdf'],
        ['e-Παράβολο', 'https://www1.gsis.gr/sgsisapps/eparavolo/public/welcome.htm']
      ]
    }),

    methods: {
      readFile (path) {
        window.open(path, '_blank')
      }
    }
  }
</script>
